import React, { useState } from 'react';
import { Parallax } from 'react-parallax';
import { useNavigate } from 'react-router-dom';
import './Projects.css';

function Projects() {
  const [showProjectOne, setShowProjectOne] = useState(false);
  const navigate = useNavigate();

  if (showProjectOne) {
    navigate('/project-one-description'); // ✅ Updated
  }

  return (
    <Parallax bgImage="/assets/StudyPlace5.webp" strength={300} className="parallax-section">
      <div id="projects" className="section-content">
        <h2>Projects</h2>
        
        <button className="project-button" onClick={() => setShowProjectOne(true)}>
          DetectText
        </button>
        
        <div className="coming-soon-section">
          <p className="coming-soon-text">Coming Soon</p>
          <button className="project-button" disabled>
            MidGuardr
          </button>
          <button className="project-button" disabled>
            SRI
          </button>
        </div>
      </div>

      {/* ✅ Image Description */}
      <div className="image-description">
        Image: Workspace
      </div>
    </Parallax>
  );
}

export default Projects;

