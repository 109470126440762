import React, { useEffect, useState } from 'react';
import { Parallax } from 'react-parallax';
import './About.css';

function About() {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById("about");
      if (section.getBoundingClientRect().top < window.innerHeight * 0.8) {
        setVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Parallax bgImage="/assets/PreLaunch.webp" strength={400} className="parallax-section">
      <div className={`section-content ${visible ? "fade-in" : ""}`} id="about">
        <h2 className="slide-in">Who dis guy?</h2>
        <p className="fade-in-delayed">Aryan is a Computer Science student at the University of Texas at Dallas with a strong foundation in ML and DL. That sounded boring. In short, he loves to learn and vision and turn them into reality using the law of attraction in daily life.</p>
        <p className="fade-in-delayed">Future ambition is in the fields of aerospace and computer vision</p>
        <p className="fade-in-delayed"><strong>Some cool things about him:</strong></p>
        <p1 className="fade-in-delayed">He don't got a driver's license, but has a rocket license. Can play the drums, piano, and guitar. Founded a soccer team called Lightwork FC. Fought a military dude and lasted 3 rounds. Solved a Rubik's cube in a minute. Sells 3D assets. Go check it out on CGTrader (Aryanmehta19). Avg Lap time PR for 5 miles straight: 6:30 mins.</p1>
      </div>

      {/* ✅ Image Description */}
    <div className="image-description">
      Image: Sunrise at Spaceport America Cup
    </div>

    </Parallax>
  );
}

export default About;