
import React from 'react';
import { Parallax } from 'react-parallax';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faDiscord, faGoogle } from '@fortawesome/free-brands-svg-icons';
import './Contact.css';

function Contact() {
  return (
    <Parallax bgImage="/assets/3DPrint_Crop.webp" strength={300} className="parallax-section">
      <div id="contact" className="section-content">
        <h2>Contact</h2>

        <div className="social-icons">
          <a href="mailto:aryanmehta1904@gmail.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faGoogle} className="social-icon" />
          </a>
          <a href="https://x.com/aryanmehta1902" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faXTwitter} className="social-icon" />
          </a>
          <a href="https://discordapp.com/users/aryanmehta1902" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faDiscord} className="social-icon" />
          </a>
        </div>
      </div>

      <div className="image-description">
            Image: Filament used for nose cone
        </div>
    </Parallax>
  );
}

export default Contact;