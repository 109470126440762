import React, { useState } from 'react';
import { Parallax } from 'react-parallax';
import './Skills.css';

function Skills() {
  const [selectedSkill, setSelectedSkill] = useState(null);

  // Skill details
  const skillsData = {
    "Computer Vision/ML": "PyTorch, TensorFlow, OpenCV, Detectron2, YOLO, MediaPipe, Ultralytics",
    "Deep Learning": "CNNs, Transformers, GANs, LSTM, Attention Networks, Transfer Learning",
    "Full Stack Development": "React, React Native, Next.js, Node.js, Express, FastAPI, Django",
    "Databases": "MongoDB, PostgreSQL, Redis, Elasticsearch, TimescaleDB",
    "Cloud/MLOps": "AWS (SageMaker, EC2, S3), Docker, Kubernetes, MLflow, DVC",
    "Programming Languages": "Python, JavaScript/TypeScript, C++, CUDA, SQL",
    "Tools & Frameworks": "Git, CI/CD, Weights & Biases, Ray, NVIDIA TensorRT, OpenVINO"
  };

  // Toggle skill details
  const handleSkillClick = (skill) => {
    setSelectedSkill((prevSkill) => (prevSkill === skill ? null : skill));
  };

  return (
    <Parallax bgImage="/assets/KalmanWhiteBoard.webp" strength={300}>
      <section id="skills" className="section fade-in">
        <h2 className="slide-in">Skills</h2>
        
        {/* Skill Buttons */}
        <div className="skills-buttons">
          {Object.keys(skillsData).map((skill) => (
            <button
              key={skill}
              className={`skill-button ${selectedSkill === skill ? "active" : ""}`}
              onClick={() => handleSkillClick(skill)}
            >
              {skill}
            </button>
          ))}
        </div>

        <div className="image-description">
            Image: Kalman Filter workings for altitude noise reduction
        </div>

        {/* Skill Details (Only Shows When a Button is Clicked) */}
        {selectedSkill && (
          <div className="skill-details fade-in">
            <h3>{selectedSkill}</h3>
            <p>{skillsData[selectedSkill]}</p>
          </div>
        )}
      </section>
    </Parallax>
  );
}

export default Skills;