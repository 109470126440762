import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { Parallax } from 'react-parallax';
import './App.css';
import About from './About';
import Projects from './Projects';
import Skills from './Skills';
import Contact from './Contact';
import ProjectOneDescrip from './ProjectOneDescrip';
import ProjectOne from './ProjectOne';
// import AudioPlayer from "./AudioPlayer"; // Import AudioPlayer

function MainApp() {
  const [bgOpacity, setBgOpacity] = useState(1);
  const sectionsRef = useRef([]);
  const heroRef = useRef(null);
  const [activeSection, setActiveSection] = useState('hero');
  const navigate = useNavigate();
  const location = useLocation();

  const smoothScroll = (event, sectionId) => {
    event.preventDefault();
  
    const section = document.getElementById(sectionId);
    if (!section) return;
  
    const navbar = document.querySelector(".navbar");
    const navbarHeight = navbar ? navbar.offsetHeight : 0;
  
    const sectionPosition = section.getBoundingClientRect().top;
    
    // ✅ Apply different offset for Hero section
    const offsetPosition = sectionId === "hero" 
      ? sectionPosition + window.pageYOffset - navbarHeight - 55  // No extra offset for Hero
      : sectionPosition + window.pageYOffset - navbarHeight + 75; // ✅ Offset for other sections
  
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = ['hero', 'about', 'projects', 'skills', 'contact'];
      const navbar = document.querySelector(".navbar");
      const navbarHeight = navbar ? navbar.offsetHeight : 0;

      const currentSection = sections.find(sectionId => {
        const section = document.getElementById(sectionId);
        if (!section) return false;
        
        const sectionTop = section.offsetTop - navbarHeight - 100;
        const sectionBottom = sectionTop + section.offsetHeight;
        return window.scrollY >= sectionTop && window.scrollY < sectionBottom;
      }) || 'hero';

      if (activeSection !== currentSection) {
        setActiveSection(currentSection);

        // ✅ Update URL without reloading the page
        const newPath = `/${currentSection === 'hero' ? '' : currentSection}`;
        if (location.pathname !== newPath) {
          navigate(newPath, { replace: true });
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); 
    const currentHeroRef = heroRef.current;
    const currentSectionsRef = [...sectionsRef.current];

    const heroObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0.2) {
            setBgOpacity(1);
          } else {
            setBgOpacity(0);
          }
        });
      },
      { threshold: [0.2] }
    );

    if (currentHeroRef) {
      heroObserver.observe(currentHeroRef);
    }

    const sectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          } else {
            entry.target.classList.remove('visible');
          }
        });
      },
      { threshold: 0.2 }
    );
    
    currentSectionsRef.forEach(section => {
      if (section) sectionObserver.observe(section);
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (currentHeroRef) heroObserver.unobserve(currentHeroRef);
      currentSectionsRef.forEach(section => {
        if (section) sectionObserver.unobserve(section);
      });
    };
  }, [activeSection, navigate, location.pathname]);

  return (
    <div className="app">
      <nav className="navbar">
        <button onClick={(e) => smoothScroll(e, "hero")} className={activeSection === "hero" ? "active-link" : ""}>Home</button>
        <button onClick={(e) => smoothScroll(e, "about")} className={activeSection === "about" ? "active-link" : ""}>About</button>
        <button onClick={(e) => smoothScroll(e, "projects")} className={activeSection === "projects" ? "active-link" : ""}>Projects</button>
        <button onClick={(e) => smoothScroll(e, "skills")} className={activeSection === "skills" ? "active-link" : ""}>Skills</button>
        <button onClick={(e) => smoothScroll(e, "contact")} className={activeSection === "contact" ? "active-link" : ""}>Contact</button>
      </nav>

      <Parallax 
        bgImage="/assets/Rocket2.webp" 
        strength={500} 
        className="parallax-hero"
        style={{ opacity: bgOpacity }}
      >
        <div ref={heroRef} className="hero-content animated-section" id="hero">
          <h1 className="pop-in">Aryan Mehta</h1>
          <p className="fade-in">Plays soccer, writes code, and builds rockets</p>
          <br />
          <p className="fade-in-delayed">
            Take this page as a source of inspiration, appreciation, or even criticism
          </p>
        </div>
      </Parallax>

      <div className="hero-image-description">
        Image: First Flight
      </div>

      <div ref={(el) => (sectionsRef.current[0] = el)} className="section animated-section"><About /></div>
      <div ref={(el) => (sectionsRef.current[1] = el)} className="section animated-section"><Projects /></div>
      <div ref={(el) => (sectionsRef.current[2] = el)} className="section animated-section"><Skills /></div>
      <div ref={(el) => (sectionsRef.current[3] = el)} className="section animated-section"><Contact /></div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainApp />} />
        <Route path="/about" element={<MainApp />} />
        <Route path="/projects" element={<MainApp />} />
        <Route path="/skills" element={<MainApp />} />
        <Route path="/contact" element={<MainApp />} />

        {/* ✅ Updated Routing */}
        <Route path="/project-one-description" element={<ProjectOneDescrip />} />
        <Route path="/project-one" element={<ProjectOne />} />
      </Routes>
      {/* ✅ Add Audio Player
      <AudioPlayer /> */}
    </Router>
  );
}

export default App;

