import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5'; // ✅ Import back arrow
import { Parallax } from 'react-parallax';
import './ProjectOneDescrip.css';

function ProjectOneDescrip() {
  const navigate = useNavigate();

  return (
    <Parallax bgImage="/assets/CompArch.jpeg" strength={500} className="parallax-container">
        {/* ✅ Back Button to Projects */}
        <button className="back-button" onClick={() => navigate('/projects')}>
          <IoArrowBack size={28} />
        </button>

      <div className="description-wrapper">

        {/* ✅ Glassmorphic Content Card */}
        <div className="description-card">
          <h2>DetectText</h2>
          <p>
            Are you interested to know how this was made?
          </p>
          <p>
            If not you can just press the button on the bottom
          </p>


          {/* ✅ Try It Button */}
          <button className="tryit-button" onClick={() => navigate('/project-one')}>
            Try It
          </button>
        </div>

      </div>
    </Parallax>
  );
}

export default ProjectOneDescrip;

