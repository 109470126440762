import React, { useEffect, useRef, useState } from 'react';
import { IoArrowBack } from 'react-icons/io5'; // Import back arrow icon
import { useNavigate } from 'react-router-dom'; // ✅ Import navigate hook
import './ProjectOne.css'; // Import CSS

const ProjectOne = () => {
  const canvasRef = useRef(null);
  const [drawing, setDrawing] = useState(false);
  const [outputText, setOutputText] = useState('');
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate(); // ✅ Initialize navigation

  useEffect(() => {
    document.body.classList.add('project-one-active');

    // ✅ Apply background image dynamically
    document.body.style.background = "url('/assets/ProjectOneBg3.jpeg') no-repeat center center fixed";
    document.body.style.backgroundSize = "cover";

    setIsMobile(window.innerWidth <= 768); // ✅ Detect mobile device

    return () => {
      document.body.classList.remove('project-one-active');
      document.body.style.background = ""; // ✅ Reset when leaving page
    };
  }, []);

  useEffect(() => {
    // ✅ Prevent scrolling when drawing on mobile
    const disableScroll = (e) => {
      if (drawing) {
        e.preventDefault();
      }
    };

    if (isMobile) {
      document.addEventListener("touchmove", disableScroll, { passive: false });
    }

    return () => {
      if (isMobile) {
        document.removeEventListener("touchmove", disableScroll);
      }
    };
  }, [drawing, isMobile]);

  // ✅ Get Accurate Touch or Mouse Position
  const getPosition = (event) => {
    const rect = canvasRef.current.getBoundingClientRect();
    let offsetX, offsetY;

    if (event.touches) {
      offsetX = event.touches[0].clientX - rect.left;
      offsetY = event.touches[0].clientY - rect.top;
    } else {
      offsetX = event.nativeEvent.offsetX;
      offsetY = event.nativeEvent.offsetY;
    }

    const scaleX = canvasRef.current.width / rect.width;
    const scaleY = canvasRef.current.height / rect.height;

    return {
      offsetX: offsetX * scaleX,
      offsetY: offsetY * scaleY
    };
  };

  // ✅ Start Drawing
  const startDrawing = (event) => {
    event.preventDefault();
    setDrawing(true);
    const ctx = canvasRef.current.getContext('2d');
    const { offsetX, offsetY } = getPosition(event);
    ctx.beginPath();
    ctx.moveTo(offsetX, offsetY);
  };

  // ✅ Draw
  const draw = (event) => {
    if (!drawing) return;
    event.preventDefault();
    const ctx = canvasRef.current.getContext('2d');
    const { offsetX, offsetY } = getPosition(event);
    ctx.lineWidth = 3;
    ctx.lineCap = 'round';
    ctx.strokeStyle = 'black';
    ctx.lineTo(offsetX, offsetY);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(offsetX, offsetY);
  };

  // ✅ Stop Drawing
  const stopDrawing = () => {
    setDrawing(false);
  };

  const clearCanvas = () => {
    const ctx = canvasRef.current.getContext('2d');
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setUploadedImage(null);
  };

  const convertToText = () => {
    setOutputText('(This would use a backend AI model to extract text)');
  };

  // ✅ Image Upload Function
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setUploadedImage(e.target.result);
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="outer-container">
      <div className="container">
        {/* ✅ Back Button now leads to ProjectOneDescrip.js */}
        <button className="back-button" onClick={() => navigate('/project-one-description')}>
          <IoArrowBack size={24} />
        </button>

        <h1>DetectText</h1>

        {/* ✅ Image Upload Section */}
        <input type="file" accept="image/*" onChange={handleImageUpload} className="upload-input" />
        
        {uploadedImage && <img src={uploadedImage} alt="Uploaded Preview" className="uploaded-image" />}

        <canvas
          ref={canvasRef}
          className="drawingCanvas"
          width={600}
          height={300}
          onMouseDown={!isMobile ? startDrawing : null}
          onMouseMove={!isMobile ? draw : null}
          onMouseUp={!isMobile ? stopDrawing : null}
          onMouseLeave={!isMobile ? stopDrawing : null}
          onTouchStart={isMobile ? startDrawing : null}
          onTouchMove={isMobile ? draw : null}
          onTouchEnd={isMobile ? stopDrawing : null}
        ></canvas>

        <div className="button-group">
          <button onClick={clearCanvas}>Clear</button>
          <button onClick={convertToText}>Convert</button>
        </div>

        <textarea
          className="outputText"
          value={outputText}
          readOnly
          placeholder="Converted text will appear here..."
        ></textarea>
      </div>
    </div>
  );
};

export default ProjectOne;
